import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { ReactComponent as IconGasPrice } from '~/assets/images/gas-price.svg'
import {
  useSwitch,
  useBreakpoint,
  useGA,
  useCurrentChainId,
  useIsValidNetwork,
} from '~/hooks'
import { getChainName, getNetworkLogo } from '~/utils/chain'
import NetworkListModal from '~/components/NetworkListModal'
import { toBigNumber } from '~/utils/numbers'
import { Mainnet, Sepolia } from '~/model/chain/ethereum'
import { useWeb3React } from '@web3-react/core'
import { switchChain } from '~/utils/switchChain'
import { getConnection } from '~/connection/utils'
import { useFeeData } from '~/state/application/hooks'
import { updatePendingChainId } from '~/state/user/actions'
import { useAppDispatch } from '~/state/hooks'
import IconWarning from '~/assets/images/warning-alert.svg'
import ArrowIcon from './ArrowIcon'

const StyledNetworkSelector = styled.div<{ isInvalid?: boolean }>`
  border: 1px solid #eff0fa;
  background: #fafafc;
  border-radius: 28px;
  padding: 0 8px;
  height: 40px;
  margin-right: 1em;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    flex: 1;
    text-align: center;
    margin-left: 5px;
    font-weight: 500;
  }

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      background: #fff8e5;
      color: ${({ theme }) => theme.colors.alertWarningText};
      border: none;
    `}

  img {
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => theme.media.sm`
    margin-right: 7px;
  `}
`

const StyledGasPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding-left: 6px;
  height: 100%;
  border-left: 1px solid #eff0fa;

  span {
    margin: 0 0 0 5px;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    color: #191c1e;
  }
`

export const NetworkSelector = () => {
  const { t } = useTranslation()
  const { account, connector } = useWeb3React()
  const connection = getConnection(connector)
  const headerChainId = useCurrentChainId()
  const isValidNetwork = useIsValidNetwork()
  const { isMobile } = useBreakpoint()
  const { trackClick } = useGA()
  const dispatch = useAppDispatch()

  const feeData = useFeeData(headerChainId)
  const gasPriceBN = toBigNumber(feeData?.gasPrice.toString()).idiv(1e9)
  const gasPrice = gasPriceBN.gte(1) ? gasPriceBN.toString() : '<1'

  const { sw: isOpen, toggle } = useSwitch(false)

  const onSelect = async (chainId: number) => {
    try {
      trackClick('webtl_network_switch', {
        caip2: `eip155:${chainId}`,
        from: 'navigation',
      })
      if (!!account) {
        await switchChain(connector, chainId)
      }
      dispatch(updatePendingChainId(chainId))
      toggle()
    } catch (error) {
      console.warn(error)
    }
  }

  const gasPriceVisible =
    !isMobile &&
    !!gasPrice &&
    (headerChainId === Mainnet.chainId || headerChainId === Sepolia.chainId)

  const iconSrc = isValidNetwork ? getNetworkLogo(headerChainId) : IconWarning
  const networkName = isValidNetwork
    ? getChainName(headerChainId)
    : t('unsupported_network')

  return (
    <>
      <StyledNetworkSelector
        onClick={toggle}
        data-testid="header-network-selector"
        isInvalid={!isValidNetwork}
      >
        <img src={iconSrc} alt={connection?.name} />
        {!isMobile && <span>{networkName}</span>}
        <ArrowIcon color={isValidNetwork ? '#9598AB' : '#D6AB3C'} />

        {gasPriceVisible && (
          <StyledGasPriceWrapper>
            <IconGasPrice />
            <span>{gasPrice}</span>
          </StyledGasPriceWrapper>
        )}
      </StyledNetworkSelector>
      <NetworkListModal
        isOpen={isOpen}
        toggle={toggle}
        chainId={headerChainId}
        onSelect={onSelect}
      />
    </>
  )
}
