import styled from 'styled-components'

const StyledLanguageArea = styled.div<{ displayThemeColor: boolean }>`
  width: 145px;
  position: relative;
  background: ${({ displayThemeColor }) =>
    displayThemeColor ? 'transparent' : '#eaecf6'};
  border-radius: 30px;
  padding: 6px 16px;
  margin-top: 12px;
`
const StyledLanguageAreaSelector = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .current-language {
    font-size: 13px;
    font-weight: normal;
    text-align: left;
    color: #9598ab;
    margin-left: 8px;
    margin-bottom: 0;
    transition: color 0.3s;
  }
  .trangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 2px 4.5px;
    border-color: #9598ab transparent transparent transparent;
    transition: border-color 0.3s;
    margin-left: auto;
    margin-top: 2px;
  }
`

const StyledLanguagePicker = styled.div`
  position: absolute;
  top: 32px;
  left: -20px;
  z-index: 1000;
  font-size: 15px;
  line-height: 26px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: rotate3d(1, 1, 0, 15deg);
  display: none;
  transform-origin: 0 100%;
  will-change: transform, display;
  transition-property: transform, display, -webkit-transform;
  transition-duration: 0.25s;
  &.visible {
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    top: -6px;
    left: 20px;
    width: 20px;
    height: 20px;
    transform: rotate(-135deg);
    border-radius: 20px 0 3px 0;
    background-color: #fff;
  }
`
const StyledOptionList = styled.div`
  color: #525f7f;
  white-space: nowrap;
  margin: 0;
  list-style: none;
  padding-left: 0;
  padding: 10px 5px;
  a {
    cursor: pointer;
    color: #5f4dbc;
    border-radius: 4px;
    display: block;
    line-height: 36px;
    margin: 0;
    padding: 0 30px;
    text-decoration: none;

    &:hover {
      background-color: #f6f9fc;
    }
  }
  &.active {
    transform: none;
    opacity: 1;
    pointer-events: auto;
    padding: 10px 5px;
  }
`
export {
  StyledLanguageArea,
  StyledLanguageAreaSelector,
  StyledLanguagePicker,
  StyledOptionList,
}
