import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useClickAway } from '~/hooks'
import { useLocalStorage } from '~/hooks/useLocalStorage'
import { LOCALES } from '~/locales'
import styled from 'styled-components'

const StyledLanguageArea = styled.div`
  position: relative;
  margin: 0 25px;
  background: transparent;

  ${({ theme }) => theme.media.sm`
    margin: 0 12px 0 0;
  `}
`

const StyledLanguageAreaSelector = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .current-language {
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 0;
    transition: color 0.3s;
  }
  .trangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 2px 4.5px;
    border-color: #333333 transparent transparent transparent;
    transition: border-color 0.3s;
    margin-left: 3px;
    margin-top: 2px;
  }
`

const StyledLanguagePicker = styled.div`
  position: absolute;
  top: 32px;
  left: -20px;
  z-index: 1000;
  font-size: 15px;
  line-height: 26px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: rotate3d(1, 1, 0, 15deg);
  display: none;
  transform-origin: 0 100%;
  will-change: transform, display;
  transition-property: transform, display, -webkit-transform;
  transition-duration: 0.25s;
  &.visible {
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    top: -6px;
    left: 20px;
    width: 20px;
    height: 20px;
    transform: rotate(-135deg);
    border-radius: 20px 0 3px 0;
    background-color: #fff;
  }
`
const StyledOptionList = styled.div`
  color: #525f7f;
  white-space: nowrap;
  margin: 0;
  list-style: none;
  padding-left: 0;
  padding: 10px 5px;
  a {
    cursor: pointer;
    color: #5f4dbc;
    border-radius: 4px;
    display: block;
    line-height: 36px;
    margin: 0;
    padding: 0 30px;
    text-decoration: none;

    &:hover {
      background-color: #f6f9fc;
    }
  }
  &.active {
    transform: none;
    opacity: 1;
    pointer-events: auto;
    padding: 10px 5px;
  }
`

const Languages: React.FC<unknown> = () => {
  const ref = useRef()
  const [visible, setVisible] = useState<boolean>(false)
  const [_, setValue] = useLocalStorage('lang', LOCALES.EN)
  const { i18n } = useTranslation()
  const isZh = i18n.resolvedLanguage === LOCALES.ZH
  const clickHandler = (
    event: React.SyntheticEvent<HTMLAnchorElement>,
    lang: LOCALES,
  ) => {
    event.preventDefault()
    setValue(lang)
    i18n.changeLanguage(lang)
    setVisible(false)
  }

  useClickAway(ref, () => setVisible(false))
  return (
    <StyledLanguageArea ref={ref}>
      <StyledLanguageAreaSelector onClick={() => setVisible((last) => !last)}>
        <p id="current-language" className="current-language">
          {isZh ? '简体中文' : 'En'}
        </p>
        <div className="trangle" />
      </StyledLanguageAreaSelector>

      <StyledLanguagePicker className={visible ? 'visible' : ''}>
        <StyledOptionList>
          <li>
            <a
              data-language="zh-CN"
              onClick={(e) => clickHandler(e, LOCALES.ZH)}
              className={isZh ? 'selected' : ''}
            >
              <span>简体中文</span>
            </a>
          </li>
          <li>
            <a
              data-language="en-US"
              onClick={(e) => clickHandler(e, LOCALES.EN)}
              className={!isZh ? 'selected' : ''}
            >
              <span>English</span>
            </a>
          </li>
        </StyledOptionList>
      </StyledLanguagePicker>
    </StyledLanguageArea>
  )
}

export default Languages
