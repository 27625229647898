import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from '~/hooks'
import { LOCALES } from '~/locales'
import styled from 'styled-components'
import PCBannerZH from '~/assets/images/header-banner-campaign-pc-zh.png'
import PCBannerEN from '~/assets/images/header-banner-campaign-pc-en.png'
import MobileBannerZH from '~/assets/images/header-banner-campaign-mobile-zh.png'
import MobileBannerEN from '~/assets/images/header-banner-campaign-mobile-en.png'

dayjs.extend(utc)
dayjs.extend(timezone)

const HeaderBanner: React.FC = () => {
  const { i18n } = useTranslation()
  const { isMobile } = useBreakpoint()

  const now = dayjs()
  const startTime = dayjs.tz('2024-08-08 12:00:00', 'Asia/Shanghai')
  const endTime = dayjs.tz('2024-08-26 12:00:00', 'Asia/Shanghai')
  if (now.isBefore(startTime) || now.isAfter(endTime)) return null

  const router = useRouter()
  if (router.pathname === '/campaign') return null

  const isZh = i18n.resolvedLanguage === LOCALES.ZH
  // const lang = isZh ? 'zh-cn' : 'en-us'

  const PCBanner = isZh ? PCBannerZH : PCBannerEN
  const MobileBanner = isZh ? MobileBannerZH : MobileBannerEN
  const Banner = isMobile ? MobileBanner : PCBanner

  // const url = `https://support.tokenlon.im/hc/${lang}/articles/14142796564884`
  const url = '/campaign'

  const handleClick = () => {
    if (url.startsWith('/')) {
      router.push(url)
    } else window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <StyledBannerWrapper onClick={handleClick}>
      <StyledBanner src={Banner.src} alt="banner" />
    </StyledBannerWrapper>
  )
}

export default HeaderBanner

const StyledBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9eeff;
  cursor: pointer;
`
const StyledBanner = styled.img`
  width: 880px;

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`
