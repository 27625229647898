import styled from 'styled-components'

export const StyledExchangeFooterWrapper = styled.div<{
  displayThemeColor: boolean
}>`
  margin-top: 120px;
  border-top: 1px solid rgba(207, 207, 230, 0.3);
  background: ${({ displayThemeColor, theme }) =>
    displayThemeColor ? theme.colors.bgColor : theme.colors.footerNormal};
`
export const StyledExchangeFooter = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 40px;
  a {
    transition: color 0.3s;
  }
  @media screen and (max-width: 640px) {
    display: none;
  }
`
export const StyledExchangeFooterMain = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 20px;
`
export const StyledExchangeFooterSection = styled.div`
  min-width: 240px;
  #current-language {
    white-space: nowrap;
  }
`

export const StyledExchangeFooterApply = styled.div`
  padding-top: 40px;
  font-size: 14px;
  .ref-links {
    display: flex;
    align-items: center;
    padding: 23px 0;
    margin: 0px auto;
    max-width: 1140px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(139, 134, 153);
  }

  .ref-links span {
    margin-right: 15px;
  }

  .ref-link {
    width: 20px;
    height: 20px;
    margin: 0 8px;
    cursor: pointer;
  }

  .exchange-footer-apply p {
    margin: 0 auto;
    max-width: 1140px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    color: #8b8699;
  }
  .footer-copyright {
    display: flex;
    justify-content: space-between;
    border-top: 0.5px solid rgba(207, 207, 230, 0.3);
    max-width: 1140px;
    margin: 0 auto;
    padding: 23px 0;

    p {
      max-width: 1140px;
      text-align: left;
      padding-left: 20px;
      padding-right: 20px;
      color: rgb(139, 134, 153);
    }

    .emails {
      a {
        color: #8b8699;
      }
    }
  }

  &.footer-lite {
    padding-top: 0;

    .footer-copyright {
      border-top: 0;
    }
  }
`

export const StyledFooterSubscribe = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  padding: 50px 0 44px;
  border-bottom: 1px solid #e6ebf2;

  h3 {
    max-width: 434px;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #2a1c4f;
    white-space: pre-line;
  }
`

export const StyledFooterSubscribeForm = styled.form`
  position: relative;

  .input {
    border: 1px solid #e6ebf2;
    border-radius: 152.6px;
    padding: 0 45px 0 57px;
    width: 480px;
    height: 56px;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    background: #ffffff;

    &::placeholder {
      color: #c4c9d9;
    }
  }

  .icon,
  .submit {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .email {
    position: absolute;
    left: 21px;
    top: 17px;
    width: 25px;
    height: 25px;
  }

  .submit {
    opacity: 0;
    z-index: 10;
  }
`
