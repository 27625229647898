import { useRouter } from 'next/router'
import IconTokenlonHeaderLogo from '~/assets/images/tokenlon-header-logo.svg'
import {
  StyledSidebarFooter,
  StyledFooterListArea,
  StyledHeaderPlaceholder,
  StyledMbCopyRight,
} from './styled'

interface SidebarProps {
  opened: boolean
  handleClose: fn
}

export const Sidebar: React.FC<SidebarProps> = ({ opened, handleClose }) => {
  const { pathname } = useRouter()

  return (
    <>
      <StyledSidebarFooter opened={opened} handleClose={handleClose}>
        <StyledHeaderPlaceholder />
        <StyledFooterListArea />
      </StyledSidebarFooter>
      {/* {pathname !== '/lon' && (
        <StyledMbCopyRight key="copyright">
          <img src={IconTokenlonHeaderLogo} />
          <p>
            © 2019 DIGITAL ERA GLOBAL LIMITED. All rights reserved.
            <a href="/tos">Terms</a>
          </p>
        </StyledMbCopyRight>
      )} */}
    </>
  )
}

export default Sidebar
