import styled, { createGlobalStyle } from 'styled-components'

export const TooltipsStyle = createGlobalStyle`
  .ant-tooltip {
    max-width: 220px;

    &.auto-width {
      max-width: max-content;
    }
  }

  .ant-tooltip-inner {
    padding: 12px !important;
    border-radius: 12px !important;
    font-size: 12px;
    font-weight: normal;
    line-height: 16.34px;
    white-space: pre-line;
    color: #eaecf6 !important;
  }

  .ant-tooltip-inner a {
    color: rgb(159, 153, 203);
    text-decoration: underline;
  }
  .ant-tooltip-inner a:hover {
    color: rgb(159, 153, 203);
  }
  .tooltip-wrap img {
    cursor: pointer;
    margin-left: 4px;
    margin-bottom: 2px;
  }

  .ant-tooltip-arrow-content {
    width: 8px;
    height: 8px;
  }

  .ant-tooltip.spot-swap-settings-tooltip {
    z-index: 100;
  }

  .ant-tooltip.spot-swap-settings-tooltip .ant-tooltip-inner {
    width: 200px;
  }

  @media screen and (max-width: 640px) {
    .ant-tooltip-inner:lang(en) {
      font-size: 12px !important;
    }
    .ant-tooltip.auto-width {
      max-width: calc(100vw - 20px) !important;
    }
    .ant-tooltip.auto-width .ant-tooltip-inner {
      overflow: auto;
    }
    .ant-tooltip.spot-swap-settings-tooltip .ant-tooltip-arrow {
      left: 80%;
    }

    .ant-tooltip.lo-wrap-eth {
      left: auto !important;
      right: 15px !important;
    }

    .ant-tooltip.lo-wrap-eth .ant-tooltip-arrow {
      left: 85%;
    }

    .ant-tooltip.spot-swap-settings-tooltip {
      left: auto !important;
      right: 20px !important;
    }
  }
`

export const StyledTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 0.2px solid #e6e8eb;
  margin-bottom: 8px;
  opacity: 0.8;

  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: #f5f7fb;

  .value {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    opacity: 0.9;
  }
`

export const StyledContent = styled.div<{ opacity?: string }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #f5f7fb;
  opacity: ${({ opacity }) => opacity ?? 0.6};
`

export const StyledLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  font-weight: 500;
  text-decoration: none !important;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
