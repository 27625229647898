import router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWeb3React } from '@web3-react/core'
import IconArrowGray from '~/assets/images/arrow-gray.svg'
import { useBreakpoint, useGA } from '~/hooks'
import { LOCALES } from '~/locales'
import MobileLinkItem from './MobileLinkItem'
import { StyledFooterListArea } from './styled'
import { useModalOpened, useModalToggle } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import { useHelpScout } from '~/hooks/useHelpScout'
import { ASSET_PREFIX } from '~/constants/env'

interface FooterListAreaProps {
  className?: string
}

interface ListItem {
  title: string
  showDivider?: boolean
  items?: Array<{ url: string; name: string }>
  url?: string
}

const useFooterData = () => {
  const { t, i18n } = useTranslation()
  const locale = i18n.resolvedLanguage
  const isZh = i18n.resolvedLanguage === LOCALES.ZH
  const en = !isZh ? 'en' : ''
  const voteUrl = isZh
    ? 'https://tokenlon.zendesk.com/hc/zh-cn/articles/360051220292'
    : 'https://tokenlon.zendesk.com/hc/en-us/articles/360051220292-How-to-use-Snapshot-to-participate-in-Tokenlon-pilot-community-governance'
  const zendeskLan = isZh ? 'zh-cn' : 'en-us'
  const targetLitepaper = `${ASSET_PREFIX}/files/${
    isZh ? 'Tokenlon-Litepaper_zh-cn' : 'Tokenlon-litepaper_en-us'
  }.pdf`

  const { isBreakPointLG } = useBreakpoint()

  const data: ListItem[] = (isBreakPointLG
    ? [
        {
          title: t('language'),
          items: [
            {
              url: `${LOCALES.ZH}`,
              name: '简体中文',
            },
            {
              url: `${LOCALES.EN}`,
              name: 'English',
            },
          ],
          showDivider: true,
        },
      ]
    : []
  ).concat([
    {
      title: t('discover'),
      items: [
        {
          url: targetLitepaper,
          name: t('litepaper'),
        },
        {
          url: `https://github.com/consenlabs/tokenlon-contracts/tree/master/audits`,
          name: t('audit_report'),
        },
        {
          url: 'https://tokenlon.im/brand',
          name: t('brand_and_logo'),
        },
      ],
      showDivider: false,
    },
    {
      title: t('developer_document'),
      showDivider: false,
      items: [
        {
          url: 'https://github.com/consenlabs/tokenlon-contracts',
          name: t('smart_contract'),
        },
        {
          url: 'https://tokenlon-v5-sdk.docs.imvercel.works/',
          name: t('sdk'),
        },
        {
          url: `https://docs.token.im/tokenlon-onboarding/${en}`,
          name: t('market_maker_document'),
        },
        {
          url: `https://docs.token.im/tokenlon-open-api?locale=${locale}`,
          name: t('open_api'),
        },
      ],
    },
    !isBreakPointLG
      ? {
          title: t('social'),
          showDivider: false,
          items: [
            {
              url: 'https://discord.gg/ZFf4Cye',
              name: 'Discord',
            },
            {
              url: 'https://forum.tokenlon.im/',
              name: t('gov_forum'),
            },
            {
              url: 'https://snapshot.org/#/tokenlon.eth',
              name: t('snapshot'),
            },
          ],
        }
      : undefined,
    {
      title: t('footer_support'),
      showDivider: false,
      items: [
        {
          url: `https://support.tokenlon.im/hc/${zendeskLan}`,
          name: t('help_center'),
        },
        {
          url: `https://support.tokenlon.im/hc/${zendeskLan}/articles/360047156012`,
          name: t('tokenlon_tutorial'),
        },
        {
          url: `https://tokenlon.gitbook.io/docs/${
            !isZh ? 'v/docs.en/' : 'v/docs.cn/'
          }`,
          name: t('wiki'),
        },
        {
          url: 'mailto:legalrequest@tokenlon.im',
          name: t('legal'),
        },
      ],
    },
  ])
  if (isBreakPointLG) {
    data.unshift(
      {
        title: t('products'),
        items: [
          { url: '/instant', name: t('instant_swap') },
          { url: '/limit-order', name: t('limit_order') },
          { url: '/weth', name: t('wrap_eth') },
        ],
      },
      {
        title: 'LON',
        items: [
          { url: '/lon', name: t('sub_nav_overview') },
          { url: '/lon/dashboard', name: t('sub_nav_dashboard') },
          { url: '/lon/mining/trade', name: t('sub_nav_trade_mining') },
          { url: '/lon/buyback', name: t('sub_nav_buyback') },
          { url: '/lon/staking', name: t('sub_nav_staking') },
          { url: '/lon/mining/lp', name: t('sub_nav_liquity_mining') },
        ],
      },
      {
        title: t('community'),
        items: [
          {
            url: 'https://forum.tokenlon.im/c/tokenlon-bounty/9',
            name: t('bounty'),
          },
          { url: 'https://forum.tokenlon.im/', name: t('forum') },
          { url: 'https://snapshot.org/#/tokenlon.eth', name: t('vote') },
          { url: 'https://twitter.com/tokenlon', name: t('twitter') },
          { url: 'https://discord.com/invite/ZFf4Cye', name: t('discord') },
        ],
      },
      {
        title: 'Blog',
        url: '/blog',
        showDivider: true,
      },
    )
    data.push({
      title: t('help_and_feedback'),
      showDivider: false,
      items: [
        {
          url: '',
          name: '',
        },
      ],
    })
  }
  return data.filter((d) => !!d)
}

export const FooterListArea: React.FC<FooterListAreaProps> = ({
  className,
}) => {
  const data = useFooterData()
  const [openedListIndex, setOpenedListIndex] = useState(-1)
  const { isBreakPointLG } = useBreakpoint()
  const { trackClick } = useGA()
  const { push } = useRouter()
  const toggleSidebar = useModalToggle(ApplicationModal.MOBILE_SIDEBAR, {
    stack: true,
  })

  const toggleHelpScout = useHelpScout()

  const handleListOpen = (index: number) => {
    const isLastItem = index === data.length - 1
    if (isLastItem && isBreakPointLG) {
      toggleHelpScout()
    }
    if (index === openedListIndex) {
      if (index !== -1) {
        setOpenedListIndex(-1)
      }
    } else {
      setOpenedListIndex(index)
    }
  }

  const onMenuClick = (url?: string) => {
    if (url) {
      push(url)
      toggleSidebar()
    }
  }

  const onTrack = (obj: Record<string, any>) => {
    trackClick('webtl_bottom_detail', obj)
  }

  return (
    <StyledFooterListArea className={className}>
      {data.map((list, index) => {
        const { title, items = [], showDivider, url } = list
        return (
          <div
            className={`${openedListIndex === index && 'active'}`}
            key={index}
            onClick={() => onMenuClick(url)}
          >
            <h3 onClick={() => handleListOpen(index)}>
              {title}
              {isBreakPointLG && items.length > 0 && (
                <img className="arrow-icon" src={IconArrowGray} />
              )}
              {isBreakPointLG && !items.length && (
                <img
                  className="arrow-icon arrow-icon__rotate"
                  src={IconArrowGray}
                />
              )}
            </h3>
            <ul>
              {items.map((item, itemIndex) => (
                <MobileLinkItem
                  onClick={() =>
                    onTrack({ type: title, title: item.name, url: item.url })
                  }
                  url={item.url}
                  name={item.name}
                  key={itemIndex}
                />
              ))}
            </ul>
            {showDivider && <hr className="list-divider" />}
          </div>
        )
      })}
    </StyledFooterListArea>
  )
}

export default FooterListArea
