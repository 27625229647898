import BigNumber from 'bignumber.js'
import {
  cardinalWords,
  ordinalWords,
  tensWords,
  chineseNums,
  chineseUnits,
} from '~/constants/numbers'

export const toBigNumber = (x: BigNumber | string | number): BigNumber => {
  if (x instanceof BigNumber) return x
  if (isNaN(Number(x))) return new BigNumber(0)

  if (typeof x === 'string') {
    if (!x) return new BigNumber(0)
    if (x.indexOf('0x') === 0 || x.indexOf('-0x') === 0) {
      return new BigNumber(x.replace('0x', ''), 16)
    }
  }
  return new BigNumber(x)
}

export const toBigNumberWithDecimal = (
  x: BigNumber | number | string,
  decimal: number = 0, // 小数点向左移动位数
): BigNumber => {
  return toBigNumber(x).dividedBy(Math.pow(10, decimal))
}

export const formatNumber = (
  x: BigNumber | number | string,
  place: number = 0, // 小数点后保留位数
  decimal: number = 0, // 小数点向左移动位数
): string => {
  if (x === undefined || x === null || x === '') return '~'
  return toBigNumber(
    toBigNumberWithDecimal(x, decimal).toFixed(place, BigNumber.ROUND_DOWN),
  ).toFormat()
}

export const formatPercentage = (
  x: BigNumber | number | string,
  place: number = 0,
  decimal: number = 0,
): string => {
  if (x === undefined || x === null || x === '') return '~'
  return `${toBigNumberWithDecimal(x, decimal)
    .multipliedBy(100)
    .toFormat(place, 1)}%`
}

export const max = BigNumber.max

export const min = BigNumber.min

export const numberToOrdinal = (num: number): string => {
  if (num < 0 || num > 1000) {
    return 'Number out of range'
  } else if (num < 20) {
    return ordinalWords[num]
  } else if (num < 100) {
    var onesDigit = num % 10
    var tensDigit = Math.floor(num / 10)
    return (
      tensWords[tensDigit] + (onesDigit ? '-' + ordinalWords[onesDigit] : '')
    )
  } else {
    var hundredsDigit = Math.floor(num / 100)
    var remainder = num % 100
    return (
      cardinalWords[hundredsDigit] +
      ' hundred' +
      (remainder ? ' and ' + numberToOrdinal(remainder) : 'th')
    )
  }
}

export const numberToOrdinalWithSuffix = (num: number): string => {
  let suffix = 'th'
  const lastDigit = num % 10
  const lastTwoDigits = num % 100

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    suffix = 'st'
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    suffix = 'nd'
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    suffix = 'rd'
  }

  return num.toString() + suffix
}

export const numberToChinese = (num: number): string => {
  if (num < 0 || num > 1000) {
    return 'Number out of range'
  } else if (num < 10) {
    return chineseNums[num]
  } else if (num < 100) {
    var onesDigit = num % 10
    var tensDigit = Math.floor(num / 10)
    return (
      (tensDigit > 1 ? chineseNums[tensDigit] : '') +
      chineseUnits[1] +
      (onesDigit ? chineseNums[onesDigit] : '')
    )
  } else {
    var hundredsDigit = Math.floor(num / 100)
    var remainder = num % 100
    return (
      chineseNums[hundredsDigit] +
      chineseUnits[2] +
      (remainder && remainder < 10 ? '零' : '') +
      (remainder ? numberToChinese(remainder) : '')
    )
  }
}

export const formatCountUnit = (num: number): string => {
  const BILLION = 1000000000
  const MILLION = 1000000
  const options = { minimumFractionDigits: 0, maximumFractionDigits: 2 }

  if (num >= BILLION) {
    return (num / BILLION).toLocaleString('en-US', options) + 'B'
  } else if (num >= MILLION) {
    return (num / MILLION).toLocaleString('en-US', options) + 'M'
  } else {
    return num.toLocaleString('en-US', options)
  }
}

export const formatFormAmount = (
  number: BigNumber | number | string,
  precision = 6,
): string => {
  const bigNumber = toBigNumber(number)

  if (bigNumber.isZero()) return '0'

  if (precision === 0) return bigNumber.toFixed(0, BigNumber.ROUND_DOWN)

  const formattedNumber = bigNumber
    .toFixed(precision, BigNumber.ROUND_DOWN)
    .replace(/\.?0+$/, '')

  if (formattedNumber === '0') {
    return `<${new BigNumber(10).pow(-precision)}`
  }

  return formattedNumber
}
