/**
 * 地址中间截断
 * @param address string ETH Address
 * @param reserve number truncate 后左右两边保留的位数
 *
 * @example
 * const addr = '0x22038cB39Dc4F045aC5053Cf7F0541eA2772C650'
 * const truncatedAddr = truncateAddress(addr)
 * // 0x22038...C650
 */
export function truncateAddress(address: string, reserve: number = 4): string {
  if (!address) return ''
  const left = address.substr(0, 2 + reserve)
  const right = address.substr(address.length - reserve)

  return `${left}...${right}`
}

export function truncateBTCAddress(
  address: string,
  reserve: number = 6,
): string {
  if (!address) return ''
  const left = address.substr(0, reserve)
  const right = address.substr(address.length - reserve)

  return `${left}...${right}`
}
