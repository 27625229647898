import FooterListArea from './FooterListArea'
import Languages from './Languages/languages'
import { SocialMedia } from './SocialMedia'
import { Sidebar } from './Sidebar'
import { Tooltip } from 'antd'
import 'antd/dist/antd.css'
import dayjs from 'dayjs'
import {
  StyledExchangeFooterWrapper,
  StyledExchangeFooter,
  StyledExchangeFooterMain,
  StyledExchangeFooterApply,
  StyledExchangeFooterSection,
  StyledFooterSubscribe,
  StyledFooterSubscribeForm,
} from './styled'
import { useTranslation } from 'react-i18next'
import { useBreakpoint, useGA } from '~/hooks'
import { useRouter } from 'next/router'
import { useLimitOrderRouter } from '~/hooks/useLimitOrderRouter'
import IconTokenlon from '~/assets/images/tokenlon-header-logo.svg'
import RefIconCoinGecko from '~/assets/images/reflink-coingecko.svg'
import RefIconArbitrum from '~/assets/images/reflink-arbitrum.svg'
import RefIconUnstoppable from '~/assets/images/reflink-unstoppable.svg'
import RefIconImToken from '~/assets/images/imtoken.svg'
import RefIconCMC from '~/assets/images/reflink-cmc.svg'
import RefIconImKey from '~/assets/images/reflink-imkey.png'
import { useModalOpened, useModalToggle } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import { RouterLink } from '../RouterLink'
import Subscribe from '~/assets/images/subscribe.svg'
import Email from '~/assets/images/email.png'

const FriendshipLinks = () => {
  const { i18n } = useTranslation()
  const lang = i18n.resolvedLanguage === 'zh' ? 'zh-cn' : 'en-us'
  const Friendships = [
    { title: 'imToken', href: 'https://token.im', icon: RefIconImToken },
    {
      title: 'imKey',
      href: `https://imkey.im?locale=${lang}`,
      icon: RefIconImKey.src,
    },
    {
      title: 'CoinGecko',
      href: 'https://www.coingecko.com',
      icon: RefIconCoinGecko,
    },
    {
      title: 'CoinMarketCap',
      href: 'https://coinmarketcap.com',
      icon: RefIconCMC,
    },
    {
      title: 'Arbitrum',
      href: 'https://portal.arbitrum.one',
      icon: RefIconArbitrum,
    },
    {
      title: 'Unstoppable',
      href: 'https://unstoppabledomains.com/partners',
      icon: RefIconUnstoppable,
    },
  ]

  const { trackElClick } = useGA()

  return (
    <>
      {Friendships.map((item) => (
        <Tooltip title={item.title} key={item.title}>
          <a
            href={item.href}
            target="_blank"
            title={item.title}
            data-trackid="webtl_bottom_external"
            data-trackdata={JSON.stringify({
              url: item.href,
              title: item.title,
            })}
            onClick={trackElClick}
          >
            <img className="ref-link" src={item.icon} alt={item.title} />
          </a>
        </Tooltip>
      ))}
    </>
  )
}

interface Props {
  footerWrapperStyle?: React.CSSProperties
}

export const Footer: React.FC<Props> = ({ footerWrapperStyle }) => {
  const { t } = useTranslation()
  const { isBreakPointLG } = useBreakpoint()
  const sidebarOpened = useModalOpened(ApplicationModal.MOBILE_SIDEBAR)
  const toggleSidebar = useModalToggle(ApplicationModal.MOBILE_SIDEBAR, {
    stack: true,
  })
  const { route, asPath } = useRouter()

  const isLimitOrder = useLimitOrderRouter()
  const { trackClick } = useGA()
  const isInstantRouter = asPath === '/instant' || /^\/exchange/g.test(route)
  const isWETHRouter = /^\/weth/g.test(route)
  const showLiteFooter = isLimitOrder || isInstantRouter
  const displayThemeColor = isLimitOrder || isInstantRouter || isWETHRouter

  if (isBreakPointLG) {
    return <Sidebar opened={sidebarOpened} handleClose={toggleSidebar} />
  }

  return (
    <StyledExchangeFooterWrapper
      data-value="StyledExchangeFooterWrapper"
      displayThemeColor={displayThemeColor}
      style={footerWrapperStyle}
    >
      {!showLiteFooter && (
        <StyledFooterSubscribe>
          <h3>{t('subscribe_newsletter')}</h3>
          <StyledFooterSubscribeForm
            action="https://9b794b26.sibforms.com/serve/MUIEAH7YOhtoj_Ryn-3_Q7AWCOUQCtYgqoNnkwxggcfyQVCu8VfMlF9gy8LFPTqL_W53zVgFc-KDhE38rAY8FzZ02JZWMwkwVBLs0NDqUS7QQqt7eW3iJmDu2ktDxGhOAEFdQjSKBQo1aHO2rfQG1GEMgHKV7x45DpW4LjGIPrwJKl2WmOm9Lu6rvOL5fl5VZkIYMlHflD0gkpww"
            method="post"
            onSubmit={(e) =>
              trackClick('webtl_bottom_email', { title: e.target[0].value })
            }
            target="_blank"
          >
            <input
              className="input"
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              placeholder={t('enter_email_address')}
              required={true}
            />
            <img className="email" src={Email.src} alt="email" />
            <img className="icon" src={Subscribe} alt="Subscribe button" />
            <input className="submit" type="submit" />
          </StyledFooterSubscribeForm>
        </StyledFooterSubscribe>
      )}

      <StyledExchangeFooter data-value="StyledExchangeFooter">
        <StyledExchangeFooterMain data-value="StyledExchangeFooterMain">
          <StyledExchangeFooterSection>
            <RouterLink href="/" className="logo-link">
              <img src={IconTokenlon} className="tokenlon-header-logo" />
            </RouterLink>
            <Languages />
          </StyledExchangeFooterSection>
          <FooterListArea />
        </StyledExchangeFooterMain>
      </StyledExchangeFooter>

      <StyledExchangeFooterApply className={showLiteFooter && 'footer-lite'}>
        <p className="ref-links">
          <span>{t('links')}</span>
          <FriendshipLinks />
        </p>
        <div className="footer-copyright">
          <p>
            © 2019 - {dayjs().year()} DIGITAL ERA GLOBAL LIMITED. All rights
            reserved.{' '}
            <a target="_blank" href="/tos" style={{ color: '#5F4DBC' }}>
              Terms
            </a>
            <span className="emails">
              {' | '}
              <a target="_blank" href="mailto:support@tokenlon.im">
                support@tokenlon.im
              </a>
            </span>
          </p>
          <SocialMedia />
        </div>
      </StyledExchangeFooterApply>
    </StyledExchangeFooterWrapper>
  )
}

export default Footer
