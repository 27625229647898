import { useTranslation } from 'react-i18next'
import Modal from '~/components/Modal'
import { NetworkConnectOptions } from '~/components/WalletModal/Components/NetworkConnectOptions'

interface Props {
  isOpen: boolean
  chainId: number
  toggle: () => void
  onSelect: (chainId: number) => void
}

const NetworkListModal: React.FC<Props> = ({
  chainId,
  onSelect,
  isOpen,
  toggle,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={toggle}
      title={t('switch_network')}
      contentClassName="switchNetworkContent"
      headerClassName="switchNetworkHeader"
    >
      <NetworkConnectOptions chainId={chainId} onSelect={onSelect} />
    </Modal>
  )
}

export default NetworkListModal
