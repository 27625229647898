import {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react'
import {
  StyledTokenlonHeader,
  StyledTabsContainer,
  StyledMainNavItem,
  StyledControls,
  ConnectButton,
  StyledLogo,
  StyledMenuIcon,
  StyledHeaderMain,
  StyledSubNavPortal,
  StyledSubNavItem,
  StyledSubNavPortalContainer,
  StyledHoverRow,
  StyledInstantButton,
} from './styled'
import { NetworkSelector } from './components/NetworkSelector'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import {
  useBreakpoint,
  useForceUpdate,
  useGA,
  useLimitOrderRouter,
} from '~/hooks'
import { Account } from '~/components/Account'
import { useWeb3React } from '@web3-react/core'
import { ApplicationModal } from '~/state/application/reducer'
import { useModalToggle } from '~/state/application/hooks'
import { RouterLink } from '../RouterLink'
import Portal from '../Portal'
import Languages from './components/Languages'
import { coinbaseWalletConnection } from '~/connection'
import HeaderBanner from './components/HeaderBanner'
// import HeaderNotificationBar from './components/HeaderNotificationBar'
import LogoPC from '~/assets/images/tokenlon-header-logo.svg'
import LogoMobile from '~/assets/images/tokenlon-logo.svg'

export interface MenuItem {
  text: string
  pathname?: string
  testid?: string
  // google analytics event id
  trackid?: string
  activeKey?: string
  subMenuItems?: MenuItem[]
}

const useNavMenu = (): MenuItem[] => {
  const { t } = useTranslation()

  return [
    {
      text: t('products'),
      pathname: '/instant',
      testid: 'site-nav-instant',
      trackid: 'webtl_products',
      activeKey: 'instant',
      subMenuItems: [
        {
          text: t('instant_swap'),
          pathname: '/instant',
          testid: 'site-nav-exchange_instant',
          trackid: 'webtl_products_instant',
        },
        {
          text: t('limit_order'),
          pathname: '/limit-order',
          testid: 'site-nav-exchange_limit_order',
          trackid: 'webtl_products_lo',
        },
        {
          text: t('wrap_eth'),
          pathname: '/weth',
          testid: 'site-nav-exchange_weth',
          trackid: 'webtl_products_weth',
        },
      ],
    },
    {
      text: 'LON',
      pathname: '/lon',
      testid: 'site-nav-lon',
      trackid: 'webtl_lon',
      activeKey: 'lon',
      subMenuItems: [
        {
          text: t('sub_nav_overview'),
          pathname: '/lon',
          testid: 'site-nav-lon_overview',
          trackid: 'webtl_lon_overview',
        },
        {
          text: t('sub_nav_dashboard'),
          pathname: '/lon/dashboard',
          testid: 'site-nav-lon_dashboard',
          trackid: 'webtl_lon_dashboard',
        },
        {
          text: t('sub_nav_trade_mining'),
          pathname: '/lon/mining/trade',
          testid: 'site-nav-lon_trade_mining',
          trackid: 'webtl_lon_trademining',
        },
        {
          text: t('sub_nav_buyback'),
          pathname: '/lon/buyback',
          testid: 'site-nav-lon_buyback',
          trackid: 'webtl_lon_buyback',
        },
        {
          text: t('sub_nav_staking'),
          pathname: '/lon/staking',
          testid: 'site-nav-lon_staking',
          trackid: 'webtl_lon_staking',
        },
        {
          text: t('sub_nav_liquity_mining'),
          pathname: '/lon/mining/lp',
          testid: 'site-nav-lon_liquity_mining',
          trackid: 'webtl_lon_liquidmining',
        },
      ],
    },
    {
      text: t('community'),
      activeKey: 'community',
      trackid: 'webtl_community',
      testid: 'site-nav-community',
      subMenuItems: [
        {
          text: t('bounty'),
          pathname: 'https://forum.tokenlon.im/c/tokenlon-bounty/9',
          testid: 'site-nav-community_bounty',
          trackid: 'webtl_community_bounty',
        },
        {
          text: t('forum'),
          pathname: 'https://forum.tokenlon.im',
          testid: 'site-nav-community_forum',
          trackid: 'webtl_community_forum',
        },
        {
          text: t('vote'),
          pathname: 'https://snapshot.org/#/tokenlon.eth',
          testid: 'site-nav-community_vote',
          trackid: 'webtl_community_vote',
        },
        {
          text: t('twitter'),
          pathname: 'https://twitter.com/tokenlon',
          testid: 'site-nav-community_twitter',
          trackid: 'webtl_community_twitter',
        },
        {
          text: t('discord'),
          pathname: 'https://discord.com/invite/ZFf4Cye',
          testid: 'site-nav-community_discord',
          trackid: 'webtl_community_discord',
        },
      ],
    },
    {
      text: 'Blog',
      activeKey: 'blog',
      pathname: '/blog',
      testid: 'site-nav-blog',
      trackid: 'webtl_blog',
    },
  ]
}

const NavMenu: React.FC = () => {
  const menuConfigs = useNavMenu()
  const { isBreakPointLG } = useBreakpoint()
  const { pathname, asPath } = useRouter()
  const rawActive = (asPath || pathname).split('/')[1]
  const defaultActive = menuConfigs[0].subMenuItems
    .map((item) => pathname.substring(1))
    .includes(rawActive)
    ? menuConfigs[0].activeKey
    : rawActive

  // 初始值为空是因为第一次访问不需要展示子导航栏
  const [active, setActive] = useState('')

  if (isBreakPointLG) return null

  return (
    <StyledTabsContainer data-testid="header-nav-menu">
      {menuConfigs.map((config) => (
        <NavMenuItem
          key={config.testid}
          menu={config}
          active={active === config.activeKey}
          defaultActiveKey={defaultActive}
          setActive={setActive}
        />
      ))}
    </StyledTabsContainer>
  )
}

const NavMenuItem: React.FC<{
  menu: MenuItem
  sub?: boolean
  active?: boolean
  defaultActiveKey?: string
  setActive?: Function
}> = ({ menu, sub, setActive, active, defaultActiveKey }) => {
  const { pathname, testid, trackid, text, subMenuItems, activeKey } = menu
  const { trackElClick } = useGA()
  const subNavRef = useRef<HTMLElement>()
  const forceUpdate = useForceUpdate()
  const Comp = sub ? StyledSubNavItem : StyledMainNavItem

  useLayoutEffect(() => {
    subNavRef.current = document.getElementById('site-sub-nav')
  }, [])

  useEffect(() => {
    forceUpdate()
  }, [subNavRef.current])

  return (
    <>
      <Comp
        pathname={pathname}
        sub={sub}
        data-testid={testid}
        data-trackid={trackid}
        onClick={trackElClick}
        navText={text}
        active={active}
        onMouseOver={() => {
          setActive(subMenuItems ? activeKey : '')
        }}
      />
      {subMenuItems && subNavRef.current && active ? (
        <SubMenuPortalItem
          portalRef={subNavRef}
          menus={subMenuItems}
          parentActiveKey={activeKey}
          defaultActiveKey={defaultActiveKey}
        />
      ) : null}
    </>
  )
}

const SubMenuPortalItem: React.FC<{
  menus: MenuItem[]
  defaultActiveKey: string
  parentActiveKey: string
  portalRef: React.MutableRefObject<HTMLElement>
}> = ({ menus, portalRef }) => {
  const { pathname: currentPathname, asPath, route } = useRouter()
  // const displayThemeColor = route === '/'

  return (
    <Portal direct containerRef={portalRef}>
      <StyledHoverRow displayThemeColor={false}>
        <StyledSubNavPortal>
          {menus.map((item) => (
            <NavMenuItem
              sub
              key={item.testid}
              menu={item}
              active={[currentPathname, asPath].includes(item.pathname)}
            />
          ))}
        </StyledSubNavPortal>
      </StyledHoverRow>
    </Portal>
  )
}

const useWalletConnectAction = () => {
  const { trackClick } = useGA()
  const toggleWalletModal = useModalToggle(ApplicationModal.WALLET_CONNECT, {
    stack: true,
  })

  return useCallback(() => {
    if (typeof window !== 'undefined' && window.ethereum?.isCoinbaseWallet) {
      return coinbaseWalletConnection.connector.activate()
    }
    trackClick('webtl_walletconnect', { from: 'navigation' })
    toggleWalletModal()
  }, [toggleWalletModal, trackClick])
}

const HeaderControls: React.FC = () => {
  const { route, asPath } = useRouter()
  const isLimitOrder = useLimitOrderRouter()
  const isInstantRouter = asPath === '/instant' || /^\/exchange/g.test(route)
  const lonPaths = ['/lon/dashboard', '/lon/mining/trade', '/lon/staking']
  const isLonRouter = lonPaths.some((p) => route.startsWith(p))
  const isWETHRouter = /^\/weth/g.test(route)
  const showAccountControls =
    isLimitOrder || isInstantRouter || isLonRouter || isWETHRouter

  return (
    <StyledControls data-testid="header-controls">
      {showAccountControls ? <AccountControls /> : <LandingControls />}
    </StyledControls>
  )
}

const AccountControls = () => {
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const connect = useWalletConnectAction()
  const { trackElClickCurring } = useGA()

  const toggleInfoModal = useModalToggle(ApplicationModal.WALLET_INFO, {
    stack: true,
  })

  return (
    <>
      <NetworkSelector />
      {account ? (
        <div
          data-trackid="webtl_mywallet"
          onClick={(e) => trackElClickCurring(e)(toggleInfoModal)}
        >
          <Account />
        </div>
      ) : (
        <ConnectButton onClick={connect}>{t('connect')}</ConnectButton>
      )}
    </>
  )
}

const LandingControls: React.FC = () => {
  const { t } = useTranslation()
  const { trackElClick } = useGA()

  return (
    <>
      <Languages />
      <StyledInstantButton
        href="/instant"
        data-trackid="webtl_startinstant"
        data-trackdata={JSON.stringify({ from: 'hp_banner' })}
        onClick={trackElClick}
      >
        {t('launch_dapp')}
      </StyledInstantButton>
    </>
  )
}

const TokenlonHeader = () => {
  return (
    <>
      {/* <HeaderNotificationBar /> */}
      <HeaderBanner />
      <StyledTokenlonHeader
        data-testid="tokenlon-header"
        displayThemeColor={false}
      >
        <HeaderMain />
      </StyledTokenlonHeader>
      <SubNavPortal />
    </>
  )
}

export const HeaderMain = () => {
  const { trackElClick } = useGA()
  const { isMobile } = useBreakpoint()
  const toggleSidebar = useModalToggle(ApplicationModal.MOBILE_SIDEBAR, {
    stack: true,
  })

  const logo = isMobile ? LogoMobile : LogoPC

  return (
    <StyledHeaderMain data-testid="site-header-main">
      <RouterLink href="/" className="logo-link">
        <StyledLogo
          data-trackid="webtl_tlpic"
          onClick={trackElClick}
          data-testid="header-brand-logo"
          src={logo}
        />
      </RouterLink>
      <NavMenu />
      <HeaderControls />
      <StyledMenuIcon onClick={toggleSidebar} />
    </StyledHeaderMain>
  )
}

export const SubNavPortal = () => {
  return (
    <StyledSubNavPortalContainer data-testid="site-sub-nav" id="site-sub-nav" />
  )
}

export default TokenlonHeader
