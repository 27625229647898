import Link from 'next/link'
import styled from 'styled-components'

export const MainNavItem: React.FC<{
  pathname?: string
  navText: string
  className?: string
  onMouseOver?: () => void
  onMouseLeave?: () => void
  onClick?: React.MouseEventHandler<HTMLOrSVGElement>
  active?: boolean
  sub?: boolean
  'data-trackid'?: string
  'data-testid'?: string
}> = ({
  pathname,
  navText,
  onMouseOver,
  sub,
  onMouseLeave,
  onClick,
  className,
  'data-testid': dataTestid,
  'data-trackid': dataTrackId,
}) => {
  const noop = () => {}
  const newTabProps =
    pathname && pathname.startsWith('http')
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {}

  return (
    <StyledNavItem
      className={className}
      onMouseOver={!sub ? onMouseOver : noop}
      onMouseLeave={!sub ? onMouseLeave : noop}
      data-testid={dataTestid}
    >
      {pathname ? (
        <Link href={pathname} passHref>
          <a
            onClick={onClick || noop}
            data-trackid={dataTrackId}
            {...newTabProps}
          >
            {navText}
          </a>
        </Link>
      ) : (
        <span onClick={onClick || noop} data-trackid={dataTrackId}>
          {navText}
        </span>
      )}
    </StyledNavItem>
  )
}

const StyledNavItem = styled.li`
  a {
    color: inherit;
  }

  a:hover {
    color: inherit;
  }
`
