import styled from 'styled-components'

const StyledFooterListArea = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  .footer-list {
    flex: 1;
    &:last-child {
      flex: 0;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 16px;
    color: #5a5d6d;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #8b8799;
  }
  li {
    margin-bottom: 14px;
  }
  a {
    color: #8b8799;
    font-size: 14px;
    &:hover {
      color: #5f4dbc;
    }
  }

  ${({ theme }) => theme.media.lg`
    .list-divider {
      border-color: #eaecf6;
      border-top: none;
      margin-bottom: 10px;
      margin-top: 45px;
    }
  `}
`
export { StyledFooterListArea }
