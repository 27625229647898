import IconClose from '~/assets/images/close.svg'
import {
  StyledSidebarWrapper,
  StyledSidebarMask,
  StyledSidebar,
} from './styled'

interface SidebarWrapProps {
  opened: boolean
  applyClassName?: string
  handleClose: fn
}

const SidebarWrap: React.FC<SidebarWrapProps> = ({
  opened,
  handleClose,
  applyClassName,
  children,
}) => {
  return (
    <StyledSidebarWrapper className={opened ? 'opened' : ''}>
      <StyledSidebarMask onClick={handleClose} className="sidebar-mask" />
      <StyledSidebar
        className={`sidebar ${applyClassName ? applyClassName : ''}`}
      >
        <img src={IconClose} className="close-icon" onClick={handleClose} />
        {children}
      </StyledSidebar>
    </StyledSidebarWrapper>
  )
}
export default SidebarWrap
