import router from 'next/router'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '~/hooks/useLocalStorage'
import { LOCALES } from '~/locales'
import { useModalToggle } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'

export interface MobileLinkItemProps {
  url?: string
  name: string
  onClick?: () => void
}

const MobileLinkItem: React.FC<MobileLinkItemProps> = ({
  url = '',
  name,
  onClick,
}) => {
  const { i18n } = useTranslation()
  const [_, setValue] = useLocalStorage('lang', LOCALES.EN)
  const toggleSidebar = useModalToggle(ApplicationModal.MOBILE_SIDEBAR, {
    stack: true,
  })

  const isLanguage = useMemo(() => url === LOCALES.ZH || url === LOCALES.EN, [
    url,
  ])
  const target = useMemo(() => (`${url}`.startsWith('/') ? '' : '_blank'), [
    url,
  ])
  const safeURL = useMemo(() => {
    if (isLanguage || !url) return '#'
    return url
  }, [isLanguage, url])

  const handleLanguageChange = (lang) => {
    setValue(lang)
    i18n.changeLanguage(lang)
  }

  const clickHandler = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    onClick?.()

    // resolve spa-router
    if (url.startsWith('/')) {
      event.preventDefault()
      console.log('router', url)
      router.push(url)
      toggleSidebar()
      return false
    }

    // resolve external links
    if (!isLanguage) return

    // resolve language change
    event.preventDefault()
    console.log('lang', url)

    const lang = url
    handleLanguageChange(lang)
  }

  return (
    <li>
      <a target={target} href={safeURL} onClick={clickHandler}>
        {name}
      </a>
    </li>
  )
}

export default MobileLinkItem
