import { useTranslation } from 'react-i18next'
import {
  StyledAccount,
  StyledAccountAvatar,
  StyledAccountInfo,
  StyledContractBadgeContainer,
  StyledIconContract,
} from './styled'
import { truncateAddress } from '~/utils/truncateAddress'
import Avatar01 from '~/assets/images/avatar-01.svg'
import Avatar02 from '~/assets/images/avatar-02.svg'
import Avatar03 from '~/assets/images/avatar-03.svg'
import Avatar04 from '~/assets/images/avatar-04.svg'
import Avatar05 from '~/assets/images/avatar-05.svg'
import { useWeb3React } from '@web3-react/core'
import {
  useNativeCurrencySymbol,
  useNativeTokenBalance,
} from '~/state/wallet/hooks'
import { useDomainName, useUpdaterChainId, useIsContractAddress } from '~/hooks'
import Tooltip from '~/components/Tooltip'
import { getConnection } from '~/connection/utils'
import iconContract from '~/assets/images/header-contract-wallet-badge.svg'

const Avatar = [Avatar01, Avatar02, Avatar03, Avatar04, Avatar05]

export const Account: React.FC = () => {
  const { t } = useTranslation()
  const { account, connector } = useWeb3React()
  const { name } = useDomainName()
  const ethBalance = useNativeTokenBalance()
  const avatarIndex = parseInt(account.substr(0, 3), 16) % 5
  const nativeSymbol = useNativeCurrencySymbol()
  const connectorInfo = getConnection(connector)
  const updaterChainId = useUpdaterChainId()
  const isContractAddress = useIsContractAddress(account, updaterChainId)

  return (
    <StyledAccount>
      <StyledAccountAvatar
        size={24}
        rounded={!connectorInfo?.icon}
        src={connectorInfo?.icon || Avatar[avatarIndex]}
        alt="avatar"
      />
      {account ? (
        <>
          <StyledAccountInfo>
            <span className="address">{name || truncateAddress(account)}</span>
            {ethBalance && (
              <span className="balance">
                {ethBalance?.toSignificant(4)} {nativeSymbol}
              </span>
            )}
          </StyledAccountInfo>
          {isContractAddress && (
            <StyledContractBadgeContainer>
              <Tooltip
                title={t('header_contract_wallet_tip')}
                trigger="hover"
                placement="bottom"
              >
                <StyledIconContract
                  src={iconContract}
                  alt="contract wallet badge"
                />
              </Tooltip>
            </StyledContractBadgeContainer>
          )}
        </>
      ) : null}
    </StyledAccount>
  )
}
