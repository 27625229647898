import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LOCALES } from '~/locales'
import { setupBeacon } from '~/utils/helpscout'

export const useHelpScout = () => {
  const { i18n } = useTranslation()
  const { account } = useWeb3React()

  useEffect(() => {
    setupBeacon(i18n.resolvedLanguage as LOCALES)
  }, [i18n.resolvedLanguage])

  return useCallback(
    (visible: boolean = true) => {
      if (!window || !window.Beacon) {
        console.warn('helpScout init failed')
        return
      }

      if (visible) {
        window.Beacon('open')
        const imToken = window.imToken || {
          agent: '',
          deviceToken: '',
        }
        window.Beacon('session-data', {
          address: account,
          agent: imToken.agent,
        })
      } else {
        window.Beacon('close')
      }
    },
    [account],
  )
}
