import styled from 'styled-components'
import SidebarWrap from '../SidebarWrap'
import FooterListArea from '../FooterListArea'

export const StyledSidebarFooter = styled(SidebarWrap)``

export const StyledHeaderPlaceholder = styled.div`
  padding-top: 64px;
`
export const StyledFooterListArea = styled(FooterListArea)`
  && {
    padding: 0 30px;
    overflow-y: scroll;
    display: block;
    h3 {
      font-weight: 500;
      font-size: 14px;
      color: #5a5d6d;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .arrow-icon {
      width: 10px;
      height: 5px;
      display: block;
      transition: transform 0.3s;

      &__rotate {
        transform: rotate(-90deg);
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0 0 2px 20px;
      display: none;
    }
    li {
      margin-bottom: 27px;
    }
    a {
      font-size: 14px;
      color: #8b8799;
    }
    .active {
      ul {
        display: block;
      }
      .arrow-icon {
        transform: rotate(180deg);
      }
    }
  }
`

export const StyledMbCopyRight = styled.div`
  height: 214px;
  background-color: #f6f8fb;
  padding-left: 8%;
  img {
    margin-top: 43px;
  }
  p {
    margin-top: 25px;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.166667px;
    color: #cfd2db;
    a {
      margin-left: 10px;
      font-size: 13px;
      color: #9598ab;
      text-decoration: none;
    }
  }
`
