import styled, { css } from 'styled-components'
import { ReactComponent as MenuIcon } from '~/assets/images/menu.svg'
import { MainNavItem } from './components/MainNavItem'

export const StyledTokenlonHeader = styled.div<{ displayThemeColor: boolean }>`
  background: ${({ displayThemeColor, theme }) =>
    displayThemeColor ? theme.colors.bgColor : theme.colors.headerNormal};
  border-bottom: ${({ displayThemeColor }) =>
    displayThemeColor ? 0 : '0.5px solid rgb(234, 236, 246)'};
  z-index: 100;

  @media screen and (max-width: 640px) {
    box-shadow: none;
  }
`

export const StyledTabsContainer = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 50px;
  margin-left: -24px;
`
export const StyledHeaderRight = styled.div`
  margin-left: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 640px) {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`
export const StyledTokenlonHeaderProfile = styled.div`
  cursor: pointer;
  .connect-wallet-btn {
    color: #5f4dbc;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #5f4dbc;
    padding: 6px 14px;
    border-radius: 10px;
    outline: none;
    width: 80px;
    cursor: pointer;
  }
  .wallet-address-btn {
    font-weight: 500;
    text-align: center;
    font-size: 11px;
    color: #5f4dbc;
    border: 1px solid #e2e5f1;
    padding: 6px 14px;
    border-radius: 10px;
    outline: none;
    background-color: #f5f7fb;
    cursor: pointer;
  }
`
export const StyledLonSubNav = styled.div`
  position: absolute;
  width: 100vw;
  top: 84px;
  z-index: 1;
  background-color: #fff;
  .lon-subNav-container {
    padding-left: 142px !important;
  }
  .sub-nav-wrapper {
    width: 1200px;
    padding-left: 0;
    margin: 0 auto;
    margin-left: 590px;
  }
`

export const StyledLogo = styled.img`
  width: 170px;
  height: 32.63px;

  ${({ theme }) => theme.media.sm`
    width: 28px;
    height: 28px;
  `}
`

export const StyledMenuIcon = styled(MenuIcon).attrs({
  'data-testid': 'header-menu-icon',
})`
  display: none;
  cursor: pointer;

  ${({ theme }) => theme.media.lg`
    display: inline-block !important;
    width: 40px;
    height: 40px;
    margin-left: 7px;   
  `}
`

export const StyledSubNavItem = styled(MainNavItem)`
  margin-right: 22px;
  margin-left: 22px;
  padding: 4px 0;
  color: #9598ab;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  &:hover {
    color: #191c1e;
  }

  ${({ active }) =>
    active &&
    css`
      background: rgba(95, 85, 169, 0.2);
      border-radius: 8px;
      color: #5f55a9;
      text-align: center;
      font-weight: bold;

      a,
      span {
        display: inline-block;
        padding: 0 15px;
        line-height: 22px;
      }

      &:first-of-type {
        margin-left: 0;
      }
    `}
`

export const StyledMainNavItem = styled(MainNavItem)`
  margin-right: 27px;
  color: #333333;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: #eaecf6;
    border-radius: 40px;
  }

  a,
  span {
    display: inline-block;
    padding: 8px 24px;
    user-select: none;
  }
`

export const StyledControls = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ConnectButton = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  border-radius: 28px;
  border: 1px solid #eff0fa;
  padding: 10px 20px;
  position: relative;
  background: ${({ theme }) => theme.colors.btnPrimary};
  white-space: nowrap;
`

export const StyledInstantButton = styled.a`
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff !important;
  border-radius: 28px;
  border: 1px solid #eff0fa;
  padding: 10px 20px;
  position: relative;
  background-color: #5f4dbc;
  white-space: nowrap;
`

export const StyledHeaderMain = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: -1px;
  height: 84px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => theme.media.lg`
    width: 100%;
    height: 60px;
    padding: 0 12px;
    justify-content: space-between;
  `}
`

export const StyledSubNavPortal = styled.div`
  display: flex;
  margin-top: 0;
  width: 1200px;
  margin: 0 auto;
  list-style: none;
  padding: 12px 0;
`

export const StyledHoverRow = styled.div<{ displayThemeColor: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  border-bottom: 0.5px solid rgb(234, 236, 246);
  background: ${({ displayThemeColor, theme }) =>
    displayThemeColor ? theme.colors.bgColor : theme.colors.headerNormal};
  z-index: 11;
`

export const StyledSubNavPortalContainer = styled.div`
  border-bottom: 0.5px solid rgb(234, 236, 246);
  background-color: #fff;
  z-index: 1;

  &:after {
    content: '';
    clear: both;
  }
`
