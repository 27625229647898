import styled from 'styled-components'

export const StyledAccount = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  background: #fafafc;
  border: 1px solid #eff0fa;
  box-sizing: border-box;
  border-radius: 28px;
  padding: 0 8px;
  min-width: 136px;
  height: 40px;
  cursor: pointer;
`

export const StyledAccountAvatar = styled.img<{
  rounded?: boolean
  size?: number
}>`
  width: 33px;
  height: 33px;
  margin-right: 8px;

  ${({ rounded }) =>
    rounded &&
    `
      border-radius: 50%;
  `}

  ${({ size }) => size && `width: ${size}px; height: ${size}px;`}

  ${({ theme }) => theme.media.sm`
    margin-right: 4px;
  `}
`

export const StyledAccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .address {
    color: #5f4dbc;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
  }
  .balance {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #9598ab;
    white-space: nowrap;
  }
`

export const StyledContractBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding-left: 6px;
  height: 100%;
  border-left: 1px solid #eff0fa;
`

export const StyledIconContract = styled.img`
  width: 15px;
  height: 15px;
`
