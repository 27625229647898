import router from 'next/router'

export const RouterLink = ({ href, className, children }) => {
  const handleClick = (e) => {
    e.preventDefault()
    router.push(href)
  }
  return (
    <a href={href} onClick={handleClick} className={className}>
      {children}
    </a>
  )
}
