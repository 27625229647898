import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { useClickAway } from '~/hooks'
import { useLocalStorage } from '~/hooks/useLocalStorage'
import { useLimitOrderRouter } from '~/hooks/useLimitOrderRouter'
import { LOCALES } from '~/locales'
import {
  StyledLanguageArea,
  StyledLanguageAreaSelector,
  StyledLanguagePicker,
  StyledOptionList,
} from './styled'
import IconChangeLanguage from '../assets/tokenlon-change-language.svg'

const Languages: React.FC<unknown> = () => {
  const ref = useRef()
  const [visible, setVisible] = useState<boolean>(false)
  const [_, setValue] = useLocalStorage('lang', LOCALES.EN)
  const { i18n } = useTranslation()
  const isZh = i18n.resolvedLanguage === LOCALES.ZH

  const { route, asPath } = useRouter()
  const isLimitOrder = useLimitOrderRouter()
  const isInstantRouter = asPath === '/instant' || /^\/exchange/g.test(route)
  const isWETHRouter = /^\/weth/g.test(route)
  const displayThemeColor = isLimitOrder || isInstantRouter || isWETHRouter

  const clickHandler = (
    event: React.SyntheticEvent<HTMLAnchorElement>,
    lang: LOCALES,
  ) => {
    event.preventDefault()
    setValue(lang)
    i18n.changeLanguage(lang)
    setVisible(false)
  }

  useClickAway(ref, () => setVisible(false))
  return (
    <StyledLanguageArea
      ref={ref}
      data-value="StyledLanguageArea"
      displayThemeColor={displayThemeColor}
    >
      <StyledLanguageAreaSelector onClick={() => setVisible((last) => !last)}>
        <img className="language-icon" src={IconChangeLanguage} />
        <p id="current-language" className="current-language">
          {isZh ? '简体中文' : 'English'}
        </p>
        <div className="trangle" />
      </StyledLanguageAreaSelector>

      <StyledLanguagePicker
        className={visible ? 'visible' : ''}
        data-value="StyledLanguagePicker"
      >
        <StyledOptionList>
          <li>
            <a
              data-language="zh-CN"
              onClick={(e) => clickHandler(e, LOCALES.ZH)}
              className={isZh ? 'selected' : ''}
            >
              <span>简体中文</span>
            </a>
          </li>
          <li>
            <a
              data-language="en-US"
              onClick={(e) => clickHandler(e, LOCALES.EN)}
              className={!isZh ? 'selected' : ''}
            >
              <span>English</span>
            </a>
          </li>
        </StyledOptionList>
      </StyledLanguagePicker>
    </StyledLanguageArea>
  )
}

export default Languages
