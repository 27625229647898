import { Tooltip as AntTooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import IconExclamationMark from '~/assets/images/exclamation-mark.svg'
import { useBreakpoint } from '~/hooks'
import { Props } from './types'
import { TooltipsStyle, StyledTitle, StyledContent, StyledLink } from './styled'

const Tooltip: React.FC<Props> = (props): JSX.Element => {
  const {
    children,
    className,
    img,
    overlayClassName,
    placement,
    mobilePlacement,
    title,
    tooltipTitle,
    tooltipTitleValue,
    link,
    tooltipContent,
  } = props
  const { isMobile } = useBreakpoint()
  const { t } = useTranslation()
  const showInfoIcon = !children

  const TooltipContent = () => (
    <>
      {tooltipTitle && tooltipTitleValue && (
        <StyledTitle>
          <span>{tooltipTitle}</span>
          <span className="value">{tooltipTitleValue}</span>
        </StyledTitle>
      )}
      <StyledContent
        opacity={tooltipTitle && tooltipTitleValue ? '0.6' : '0.8'}
      >
        {title}
      </StyledContent>
      {link && (
        <StyledLink href={link} target="_blank" rel="noopener noreferrer">
          {t('learn more')}
        </StyledLink>
      )}
    </>
  )

  return (
    <>
      <TooltipsStyle />
      <AntTooltip
        trigger="click"
        {...props}
        title={tooltipContent ?? TooltipContent}
        color="#262C4B"
        className={`lon-tooltip ${className ?? ''}`}
        overlayClassName={`lon-tooltip ${overlayClassName} ${
          overlayClassName ?? ''
        }`}
        overlayStyle={{ marginRight: '24px' }}
        placement={
          isMobile ? mobilePlacement || 'bottom' : placement || 'right'
        }
      >
        {children}
        {showInfoIcon && (
          <span className="tooltip-wrap">
            <img src={img || IconExclamationMark} alt="" />
          </span>
        )}
      </AntTooltip>
    </>
  )
}

export default Tooltip
